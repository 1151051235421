<template>
  <content-wrapper :title="labels.LBL_SUBSCRIBERS">
    <el-table
      v-loading="loading"
      :data="subscriberList"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
      :empty-text="labels.LBL_NO_DATA"
    >
      <el-table-column prop="email" :label="labels.LBL_EMAIL">
      </el-table-column>

      <el-table-column :label="labels.LBL_SUBSCRIBED_ON">
        <template slot-scope="scope">
          {{ scope.row.created_at | readableDate }}
        </template>
      </el-table-column>

      <el-table-column align="right" width="250px">
        <template slot="header" slot-scope="scope">
          <el-input
            v-model="search"
            size="mini"
            :placeholder="labels.LBL_TYPE_2_SEARCH"
          />
        </template>

        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            circle
            @click="handleDelete(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="pagination"
      :current-page.sync="pagination.current_page"
      :total="pagination.total"
      :page-size="parseInt(pagination.per_page)"
      layout="total, prev, pager, next"
      class="my-2"
      @current-change="handlePageChange"
    >
    </el-pagination>
  </content-wrapper>
</template>

<script>
  import _ from "lodash";
  import { mapState } from "vuex";
  import { labels } from "@/common";
  import ContentWrapper from "@/components/layouts/AdminContentWrapper";

  export default {
    name: "Subscribers",

    components: {
      ContentWrapper,
    },

    data() {
      return {
        labels,

        search: "",
        params: {
          page: 1,
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        subscribers: (state) => state.subscription.subscribers,
      }),

      subscriberList() {
        return this.subscribers && this.subscribers.data;
      },

      pagination() {
        return this.subscribers && this.subscribers.meta;
      },
    },

    watch: {
      search: _.debounce(function(nv) {
        this.handleSearch(nv);
      }, 500),

      "params.page": {
        immediate: true,
        handler(nv) {
          if (nv) this.loadSubscribers();
        },
      },
    },

    methods: {
      loadSubscribers() {
        this.$store.dispatch("subscription/getAllSubscribers", this.params);
      },

      handleSearch(search) {
        this.$store.dispatch("subscription/getAllSubscribers", {
          ...this.params,
          search,
        });
      },

      handlePageChange(val) {
        this.params.page = val;
      },

      handleDelete({ id }) {
        this.$confirm(`${labels.CONF_DELETE}?`, labels.LBL_WARN, {
          confirmButtonText: labels.LBL_YES,
          cancelButtonText: labels.LBL_CANCEL,
          type: labels.LBL_WARN.toLowerCase(),
        })
          .then(() => {
            this.$store.dispatch("subscription/deleteSubscriber", id);
          })
          .catch(() => {
            this.$message({
              type: labels.LBL_INFO.toLowerCase(),
              message: labels.CNCL_DELETE,
            });
          });
      },
    },
  };
</script>

<style></style>
